import React from 'react';
import './App.css';
import { Container } from '@mui/material';
import { EvenSwiper } from './components/even-swiper';

function App() {
  return (
    <div className="App">

      <div className='home-title-section'>
        <Container>
          <img src='/images/logo.png' alt='' className='logo' />
          <h3>Bringing Together Houston's Energy Professionals <br />for Fun and Networking</h3>
          <h4>It's like Linked In with Beer!</h4>
        </Container>
      </div>

      <div className='home-title-intro'>
        <Container>
          <div className='logo-section'>
            <img src='/images/eiq-logo.png' alt='' height={40} style={{ cursor: 'pointer' }} onClick={() => { window.open('https://www.eiqdigital.com') }} />
            <img src='/images/enerex-magenta.png' alt='' height={30} />
            <img src='/images/ampe-logo.png' alt='' height={29} />
            <img src='/images/amp-logo.png' alt='' height={42} />
            <img src='/images/redball-logo.png' alt='' height={62} />
            <img src='/images/verle-logo.png' alt='' height={20}/>
          </div>
        </Container>
      </div>

      <Container>
        <div className='home-content'>
          <img src='/images/icon-beer.png' alt='' />
          <h4>Welcome to Electric Thursday – Where Energy Dreams Come to Life!</h4>
          <p>Picture a crisp fall day in 2022, where a gathering of seasoned Retail Energy comrades huddled together, sipping on cold brews, and swapping industry tales. As the third round of drinks flowed, a brilliant idea struck us like lightning: Houston deserves a quarterly Energy Industry networking soirée that's not just casual and fun but an event everyone eagerly anticipates.</p>
        </div>
          <EvenSwiper/>
        <div className='home-swiper-link'>
          <img className='blue-dev' src='/images/icon-in.png' alt='' width={50} style={{cursor:"pointer"}} onClick={()=> window.open("https://www.linkedin.com/company/electricthursday/")}/>
          <h4>Want to learn more?</h4>
          <a href='https://www.linkedin.com/company/electricthursday/' target='_blank'>Please follow our LinkedIn</a>
        </div>

        <div className='home-content2'>
          <div className='home-content2-content'>
            <img src='/images/icon-happy.png' alt='' height={50} />
            <p>Thus, on that fateful day, Electric Thursday was born!</p>
          </div>
        </div>

        <div className='home-content3'>
          <h4>Why should you mark your calendar for Electric Thursday?</h4>
          <ul>
            <li className='li-color1'>
              <p>1 <span>Network with Energy Insiders</span></p>
              <label>Rub shoulders with old friends and industry colleagues, and forge new connections with the movers and shakers of the energy world.</label>
            </li>

            <li className='li-color2'>
              <p>2 <span>Reconnect with Your Past</span></p>
              <label>Ever wondered where your former colleagues, both friends and foes, have ended up? Electric Thursday is the place to catch up and find out.</label>
            </li>

            <li className='li-color2'>
              <p>3 <span>Stay in the Loop</span></p>
              <label>Get the inside scoop on industry gossip and stay informed about the latest mergers and acquisitions – because knowing who's buying who is half the battle.</label>
            </li>

            <li className='li-color1'>
              <p>4 <span>Unadulterated Fun</span></p>
              <label>Most importantly, Electric Thursday is all about having a blast. It's where industry excellence meets a great time for all.</label>
            </li>
            <div className="clear"></div>
          </ul>
        </div>
        <p className='intro-label'>Don't miss out on the ultimate blend of networking, nostalgia, industry insights, and pure enjoyment. Join us at Electric Thursday, where connections are made, stories are shared, and fun is guaranteed!</p>

        <div className='home-content4'>
          <h3>Join Us for Our Next Electric Thursday!</h3>
          <p>The upcoming event is set for November—exact date to be announced.</p>
          <p>Keep an eye out for updates!</p>
          {/* <p><img src='/images/icon-clock.png' alt='' />Thursday, September 12th, 2024 @ 5:00pm CT</p> */}
          {/* <p className='padding-p' style={{ textDecoration: 'underline' }}>EZ’s Liquor Lounge</p> */}
          {/* <p><img src='/images/icon-map.png' alt='' />Axelrad Beer Garden, 1517 Alabama St, Houston, TX</p> */}
          {/* <p className='padding-p'> </p> */}
          <h2>Stay Tuned for Updates on </h2>
          <h2>Next Event！</h2>
        </div>

        <h1>Get Ready to Blow It Out!!</h1>
      </Container>

      <div className='footer'>
        <Container>
          <p>For more electrifying updates and to connect with fellow energy enthusiasts, follow us on our <a onClick={() => { window.open('https://www.linkedin.com/company/electricthursday/') }}>LinkedIn</a> page. After all, the current flows better when we're connected!</p>
        </Container>
      </div>
    </div >
  );
}

export default App;
